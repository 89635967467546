import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5e49db56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-wrapper" }
const _hoisted_2 = { class: "base-container" }
const _hoisted_3 = { class: "base-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalTestRules = _resolveComponent("ModalTestRules")!
  const _component_ModalTrajectory = _resolveComponent("ModalTrajectory")!
  const _component_ModalGoToEducation = _resolveComponent("ModalGoToEducation")!
  const _component_ModalSuccess = _resolveComponent("ModalSuccess")!
  const _component_ModalFail = _resolveComponent("ModalFail")!
  const _component_HomeHeader = _resolveComponent("HomeHeader")!
  const _component_HomeFooter = _resolveComponent("HomeFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ dpo: _ctx.hasDpo })
  }, [
    _createVNode(_component_router_view),
    _createVNode(_component_ModalTestRules),
    _createVNode(_component_ModalTrajectory),
    _createVNode(_component_ModalGoToEducation),
    _createVNode(_component_ModalSuccess),
    _createVNode(_component_ModalFail),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_HomeHeader),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_HomeFooter)
      ])
    ])
  ], 2))
}