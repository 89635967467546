<template>
  <ModalSignIn />
  <ModalRegistration />

  <div :class="{ dpo: hasDpo }">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// import { ModalsContainer } from "vue-final-modal";
import { ModalSignIn } from "@/modals/ModalSignIn/index";
import { ModalRegistration } from "@/modals/ModalRegistration/index";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "App",
  components: {
    // ModalsContainer,
    ModalSignIn,
    ModalRegistration,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasDpo = computed(
      () => route.matched[0]?.name === RouteName.DPO || route.path === "/"
    );

    // const goToDpoPage = () =>
    //   router.push({ name: RouteName.HOME_PROGRAMS_LIST, query: { id: 4 } });

    // goToDpoPage();

    return {
      hasDpo,
    };
  },
});
</script>

<style lang="stylus">
html {
  line-height: 1.3;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", Arial, sans-serif;
  overflow-x: hidden;
  getFont("text_24");

  select, input, textarea {
    font-family: "Roboto", Arial, sans-serif;
    getFont("text_24");
  }

  *, *::before, &::after {
    box-sizing: border-box;
  }
}

.bold-text {
  font-weight: bold;
}

.opacity-text {
  color: $colorDarkGrey;
}

.main-color-text {
  color: $colorPrimary;
}
.dpo .main-color-text {
  color: $colorPrimaryDpo;
}

a,
.link-style {
  display: inline;
  border: none;
  text-decoration: underline;
  getFont("text_24");
  color: $colorFontBase;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}

h1 {
  getFontH1();
}

h2 {
  getFontH2();
}

h3 {
  getFontH3();
}

h4 {
  getFontH4();
}

h5 {
  getFontH5();
}

h6 {
  getFontH6();
}

input[type=radio],
input[type=checkbox] {
   accent-color: $colorPrimary;
}

.dpo input[type=radio],
.dpo input[type=checkbox] {
  accent-color: $colorPrimaryDpo;
}

.icon-grey {
  filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(93%) contrast(91%);
}

.icon-red {
  filter: invert(37%) sepia(68%) saturate(2279%) hue-rotate(348deg) brightness(92%) contrast(91%);
}
</style>
