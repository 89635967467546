<template>
  <LayoutHomepage>
    <CommonIntro />

    <div class="page-section">
      <div class="nav-overflow">
        <nav class="tabs-menu">
          <ul class="nav nav-tabs">
            <li
              class="nav-item intro__nav"
              v-for="item in homeNavList"
              :key="item.label"
              v-show="
                !(
                  (item.label === 'Документы' ||
                    item.label === 'Приемная кампания') &&
                  hasCourses
                )
              "
            >
              <router-link
                v-if="
                  !(
                    (item.label === 'Документы' ||
                      item.label === 'Приемная кампания') &&
                    hasCourses
                  )
                "
                :class="[
                  'nav-link',
                  {
                    active:
                      pathName(
                        item.pathOrdinatName,
                        item.pathAspirantName,
                        item.pathCoursesName
                      ).value === route?.name,
                  },
                ]"
                :to="{
                  name: pathName(
                    item.pathOrdinatName,
                    item.pathAspirantName,
                    item.pathCoursesName
                  ).value,
                  query: route.query,
                }"
                @click="toggleBodyStyle"
              >
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <h2 class="section-title">
        {{ programOrd?.title }}
      </h2>
      <div class="program">
        <div class="card-row">
          <div class="card-head">
            <div class="card-image">
              <img :src="programOrd?.media?.banner1?.url" />
            </div>
          </div>
          <div class="card-col">
            <h4 class="card-title">
              {{ programOrd?.desc?.header }}
            </h4>
            <div class="card-text">
              {{ programOrd?.desc?.desc }}
            </div>
            <div class="card-params">
              <div class="params-item">
                <svg class="svg-icon">
                  <use xlink:href="#clock-icon--sprite" />
                </svg>
                <div class="param-text">
                  <p>{{ learningTime(programOrd?.learning_time) }}</p>
                  <small>срок обучения</small>
                </div>
              </div>
              <div class="params-item">
                <svg class="svg-icon">
                  <use xlink:href="#wallet-money-icon--sprite" />
                </svg>
                <div class="param-text">
                  <p>{{ programOrd?.learning_format }}</p>
                </div>
              </div>
              <div class="params-item">
                <svg class="svg-icon">
                  <use xlink:href="#monitor-icon--sprite" />
                </svg>
                <div class="param-text">
                  <p>Удобный формат</p>
                  <small>{{ programOrd?.form }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-col">
            <a href="" class="btn btn-primary">Задать вопрос</a>
          </div>
          <div class="card-col">
            <a href="" class="btn btn-secondary">Подать заявку на обучение</a>
          </div>
        </div>
      </div>
    </div>

    <section class="relevant">
      <h2 class="primary-title">Кому подходит обучение</h2>
      <div class="relevant__body">
        <p>{{ programOrd?.for_whom }}</p>
      </div>
    </section>

    <section class="benefits" v-if="!hasCourses">
      <h2 class="section-title">Преимущества</h2>
      <div class="benefits-row">
        <div
          class="benefits__item"
          v-for="item in programOrd?.advantages?.main"
          :key="item"
        >
          <div class="benefit">
            {{ item }}
          </div>
        </div>
      </div>
    </section>

    <section class="program-parts" v-else>
      <div class="container">
        <div class="program-parts__head">
          <h2 class="section-title">В программу обучения входит</h2>
          <p class="small">{{ programOrd?.program_includes?.main_text }}</p>
        </div>
        <div class="program-parts__body">
          <div
            class="program-parts__item"
            v-for="(item, i) in programOrd?.program_includes?.list"
            :key="item.title"
          >
            <figure class="parts-figure">
              <svg class="svg-icon" v-if="i === 0">
                <use xlink:href="#dpo-icon-1--sprite" />
              </svg>
              <svg class="svg-icon" v-else-if="i === 1">
                <use xlink:href="#dpo-icon-2--sprite" />
              </svg>
              <svg class="svg-icon" v-else-if="i === 2">
                <use xlink:href="#dpo-icon-3--sprite" />
              </svg>
              <svg class="svg-icon" v-else>
                <use xlink:href="#dpo-icon-4--sprite" />
              </svg>
              <figcaption class="figure-caption">
                <div class="figure-name">{{ item.title }}</div>
                <small>{{ item.desc }}</small>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>

    <section class="outcomes">
      <div class="container">
        <h2 class="section-title">Чему вы научитесь</h2>
        <ol class="outcomes-list">
          <li v-for="skill in programOrd?.skills" :key="skill">{{ skill }}</li>
        </ol>
      </div>
    </section>

    <section class="passings">
      <div class="container">
        <h2 class="section-title">Как проходит обучение</h2>
        <div class="passings-wrap">
          <div class="passings-row">
            <div
              class="passings__item"
              v-for="item in programOrd?.how_learn_going"
              :key="item.title"
            >
              <div class="passing-card">
                <div class="card-title">{{ item.title }}</div>
                <div class="card-body">
                  <div class="card-text">
                    {{ item.desc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="teaching">
      <div class="container">
        <h2 class="section-title">Кто будет вас обучать</h2>
        <div class="teaching-row">
          <div
            class="teaching__item"
            v-for="(teacher, index) in programOrd?.teachers"
            :key="teacher"
          >
            <figure class="teaching-figure">
              <!-- <svg class="svg-icon">
                <use xlink:href="@/assets/img/icons/sprite.svg#{{ index }}"></use>
              </svg> -->
              <svg class="header__icon" v-if="index === 0">
                <use xlink:href="#teacher-icon-1--sprite" />
              </svg>
              <svg class="header__icon" v-else-if="index === 1">
                <use xlink:href="#teacher-icon-2--sprite" />
              </svg>
              <svg class="header__icon" v-else>
                <use xlink:href="#teacher-icon-3--sprite" />
              </svg>
              <figcaption class="figure-caption">
                {{ teacher }}
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>

    <CommonApplication :degree_id="degreeId" :program_id="programId" />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "../HomeProgramsListPage/components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { homeNavList } from "./constants";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import store from "@/store";

import "@/assets/img/icons/teacher-icon-1.svg?sprite";
import "@/assets/img/icons/teacher-icon-2.svg?sprite";
import "@/assets/img/icons/teacher-icon-3.svg?sprite";
import "@/assets/img/icons/clock-icon.svg?sprite";
import "@/assets/img/icons/wallet-money-icon.svg?sprite";
import "@/assets/img/icons/monitor-icon.svg?sprite";
import "@/assets/img/icons/dpo-icon-1.svg?sprite";
import "@/assets/img/icons/dpo-icon-2.svg?sprite";
import "@/assets/img/icons/dpo-icon-3.svg?sprite";
import "@/assets/img/icons/dpo-icon-4.svg?sprite";

export default defineComponent({
  name: "HomePage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programOrd = computed(() => store.getters.programsStore.data);

    const hasOrdinat = computed(() => useRoute()?.path.includes("ordinat"));
    const hasAspirant = computed(() => route?.path.includes("aspirant"));
    const hasCourses = computed(() => route?.path.includes("courses"));

    const bodyStyleName = computed(() => {
      if (route?.name === RouteName.HOME_ORDINAT) {
        return "ordinat";
      } else if (route?.name === RouteName.HOME_ASPIRANT) {
        return "aspirant";
      }
      return "courses";
    });

    const learningTime = (obj: any) => {
      if (obj?.hours) {
        return `${obj?.hours} ак.ч.`;
      } else if (obj?.days) {
        return `${obj?.days} д.`;
      }

      return `${obj?.years} г.`;
    };

    const queryId = computed(() => route?.query?.id);

    const programId = computed(() => {
      if (queryId.value) return Number(queryId.value);

      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const degreeId = computed(() => {
      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const pathName = (ordinatPath: any, aspirantPath: any, coursesPath: any) =>
      computed(() => {
        if (hasOrdinat.value) {
          return ordinatPath;
        } else if (hasAspirant.value) {
          return aspirantPath;
        }
        return coursesPath;
      });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getPrograms({ program_id: programId.value });
    });

    return {
      programId,
      degreeId,
      hasCourses,
      route,
      programOrd,
      homeNavList,
      learningTime,
      toggleBodyStyle,
      pathName,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}
</style>
