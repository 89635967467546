<template>
  <div :class="{ dpo: hasDpo }">
    <router-view />

    <ModalTestRules />
    <ModalTrajectory />
    <ModalGoToEducation />
    <ModalSuccess />
    <ModalFail />

    <div class="base-wrapper">
      <div class="base-container">
        <HomeHeader />

        <div class="base-content">
          <slot />
        </div>

        <HomeFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { navList, navDpoList } from "./constants";
import { HomeHeader } from "./components/HomeHeader";
import { HomeFooter } from "./components/HomeFooter";
import { ModalTestRules } from "@/modals/ModalTestRules/index";
import { ModalTrajectory } from "@/modals/ModalTrajectory/index";
import { ModalGoToEducation } from "@/modals/ModalGoToEducation/index";
import { ModalSuccess } from "@/modals/ModalSuccess/index";
import { ModalFail } from "@/modals/ModalFail/index";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "LayoutHomepage",
  components: {
    HomeFooter,
    HomeHeader,
    ModalTestRules,
    ModalTrajectory,
    ModalGoToEducation,
    ModalSuccess,
    ModalFail,
  },

  setup() {
    const route = useRoute();

    const hasDpo = computed(
      () => route.matched[0]?.name === RouteName.DPO || route.path === "/"
    );

    return {
      hasDpo,
      navDpoList,
      navList,
    };
  },
});
</script>

<style lang="stylus" scoped>
.base-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
}

.base-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  width: 100%;
}

.base-content {
  flex-grow: 1;
  width: 100%;
  // padding: 20px 15px 40px;
  +mediaTablet() {
    // padding: 1.9vw 13.6vw 7.8vw;
    // margin-left: -12px;
    // margin-right: -12px;
  }
}

.base-navigation {
  margin-bottom: 50px;

  +mediaTablet() {
    margin-bottom: 5vw;
  }
}
</style>
