import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6a435158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalGoToEducation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonBannerIiMessage = _resolveComponent("CommonBannerIiMessage")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    class: _normalizeClass({ dpo: _ctx.hasDpo }),
    modelValue: _ctx.test,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.test) = $event)),
    name: "ModalGoToEducation",
    "modal-id": "ModalGoToEducation",
    zIndexFn: () => 1000
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonBannerIiMessage, {
          title: _ctx.bannerTitle,
          text: ""
        }, null, 8, ["title"]),
        _createVNode(_component_CommonButtonSecondary, {
          class: "modalGoToEducation__btn",
          onClick: _ctx.goToEducation
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Перейти к обучению ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["class", "modelValue"]))
}