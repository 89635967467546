<template>
  <footer class="footer-wrap">
    <div class="footer-bg" />

    <div class="footer">
      <div class="footer__logoContainer">
        <svg class="footer__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </div>

      <div class="footer__content">
        <div class="footer__infoContainer">
          <a class="footer__link" v-for="link in links" :key="link">
            {{ link }}
          </a>
        </div>

        <div class="footer__socialsContainer">
          <a href="https://t.me/niiozmm">
            <svg class="footer__icon">
              <use xlink:href="#telegram-white-icon--sprite" />
            </svg>
          </a>

          <a href="https://vk.com/niiozmm">
            <svg class="footer__icon">
              <use xlink:href="#vk-white-icon--sprite" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import "@/assets/img/icons/logo-white-icon.svg?sprite";
import "@/assets/img/icons/telegram-white-icon.svg?sprite";
import "@/assets/img/icons/youtube-white-icon.svg?sprite";
import "@/assets/img/icons/rutube-white-icon.svg?sprite";
import "@/assets/img/icons/vk-white-icon.svg?sprite";

export default defineComponent({
  name: "HomeFooter",
  components: {},

  setup() {
    const links = ["О проекте", "Программы", "Контакты"];

    return {
      links,
    };
  },
});
</script>

<style lang="stylus" scoped>
.footer-wrap {
  width: 100%;
  height: 15vw;
  min-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
}

.footer-bg {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(20%);
}

.footer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: auto;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &__logo {
    width: 130px;
    height: 60px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }

  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 1.2vw;
  }

  &__link {
    color: $colorFiller;
    font-weight: bold;
    text-decoration: none;
  }

  &__socialsContainer {
    display: flex;
    gap: .8vw;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  +mediaTablet() {
    &__logo {
      width: 15vw;
    }

    &__infoContainer {
      flex-direction: row;
    }

    &__icon {
      width: 40px;
      height: 40px;
    }
  }

  @media (min-width:1400px) {
    max-width: 1400px;
  }
}
</style>
