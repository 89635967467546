<template>
  <LayoutHomepage>
    <CommonIntro />

    <div class="programsSort" v-if="hasCourses">
      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === sortValue,
              programsSort__item_disabled: item.value === '-',
            },
          ]"
          v-for="item in sortList1"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === +sortValue,
            },
          ]"
          v-for="item in sortList2"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === +sortValue,
            },
          ]"
          v-for="item in sortList3"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="programsSort__itemsBox">
        <div
          :class="[
            'programsSort__item',
            {
              programsSort__item_active: item.value === sortValue,
            },
          ]"
          v-for="item in sortList4"
          :key="item.title"
          @click="setSortValue(item.value, item.name)"
        >
          {{ item.title }}
        </div>

        <div
          class="programsSort__item programsSort__itemClear"
          @click="clearFilters"
        >
          Сбросить фильтры
        </div>
      </div>

      <CommonInput
        v-model="searchValue"
        class="programsSort__search"
        placeholder="Быстрый поиск по программам"
        @input="handleDebounceSearch"
      >
        <template #suffix>
          <svg class="programsSort__searchIcon">
            <use xlink:href="#search-icon--sprite" />
          </svg>
        </template>
      </CommonInput>
    </div>

    <div class="programsList">
      <div
        class="programsList__item"
        v-for="program in degreeList"
        :key="program.id"
      >
        <div class="programsList__imgBox">
          <div class="programsList__imgWrap" />

          <img class="programsList__img" :src="program?.media?.banner1?.url" />

          <div v-if="hasCourses" class="programsList__imgContent">
            <div class="programsList__nmo" v-if="program?.is_nmo_balls">
              Баллы НМО
            </div>
          </div>
        </div>

        <h6 class="programsList__title">{{ program.title }}</h6>

        <div class="programsList__tabsList">
          <div class="programsList__listItem">
            {{ learningTime(program?.learning_time) }}
          </div>
          <div class="programsList__listItem">
            {{ program?.form }}
          </div>
        </div>

        <div class="form-submit dpo">
          <CommonButtonPrimary
            class="programsList__btn"
            type="submit"
            @click="goToProgram(program.id)"
          >
            Подробнее
          </CommonButtonPrimary>
        </div>
      </div>
    </div>

    <CommonApplication :degree_id="degreeId" />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "./components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { CommonInput } from "@/components/CommonInput";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import {
  homeNavList,
  sortList1,
  sortList2,
  sortList3,
  sortList4,
} from "./constants";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import store from "@/store";
import debounce from "debounce";

import "@/assets/img/icons/search-icon.svg?sprite";

export default defineComponent({
  name: "HomeProgramsListPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
    CommonInput,
    CommonButtonPrimary,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasOrdinat = computed(() => Number(route?.query?.id) === 1);
    const hasAspirant = computed(() => Number(route?.query?.id) === 2);
    const hasCourses = computed(
      () => Number(route?.query?.id) === 4 || !route?.query?.id
    );

    const sortValue = ref("");
    const sortName = ref("");

    const searchValue = ref("");

    const degreeList = computed(() => {
      if (sortName.value === "period") {
        return store.getters.programsStore?.listData.filter((item: any) => {
          return item?.learning_time?.hours === sortValue.value;
        });
      }

      return store.getters.programsStore?.listData;
    });

    const bodyStyleName = computed(() => {
      if (hasOrdinat.value) {
        return "ordinat";
      } else if (hasAspirant.value) {
        return "aspirant";
      }
      return "courses";
    });

    const degreeId = computed(() => {
      if (hasOrdinat.value) {
        return 1;
      } else if (hasAspirant.value) {
        return 2;
      }

      return 4;
    });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    const goToProgram = (id: number) => {
      const scrollToTop = () => window.scrollTo(0, 0);

      if (hasAspirant.value) {
        router.push({ name: RouteName.HOME_ASPIRANT, query: { id } });
        toggleBodyStyle();
        scrollToTop();
      } else if (hasCourses.value) {
        router.push({ name: RouteName.HOME_COURSES, query: { id } });
        toggleBodyStyle();
        scrollToTop();
      }
    };

    const learningTime = (obj: any) => {
      if (obj?.hours) {
        return `${obj?.hours} ак.ч.`;
      } else if (obj?.days) {
        return `${obj?.days} д.`;
      }

      return `${obj?.years} г.`;
    };

    const dispatchAllList = () =>
      store.dispatch.programsStore.getDegreeList({ degree_id: degreeId.value });

    const clearFilters = () => {
      dispatchAllList();

      sortValue.value = "";
      sortName.value = "";

      searchValue.value = "";
    };

    const setSortValue = (value: any, name: any) => {
      if (sortValue.value === value || sortValue.value === "-") {
        sortValue.value === "";
        sortName.value = "";
      } else {
        sortValue.value = value;
        sortName.value = name;

        if (name !== "period") {
          if (value === "") {
            dispatchAllList();
          } else {
            store.dispatch.programsStore.getDegreeList({
              degree_id: degreeId.value,
              hasFilters: true,
              filterName: name,
              filterValue: value,
            });
          }
        } else {
          dispatchAllList();
        }
      }
    };

    const handleSearch = () =>
      store.dispatch.programsStore.getDegreeList({
        degree_id: degreeId.value,
        hasFilters: true,
        filterName: "search",
        filterValue: searchValue.value,
      });

    const handleDebounceSearch = debounce(handleSearch, 1000);

    onMounted(async () => {
      toggleBodyStyle();

      dispatchAllList();

      store.dispatch.programsStore.getCategories({ degree_id: degreeId.value });
    });

    return {
      degreeId,
      hasCourses,
      route,
      homeNavList,
      degreeList,
      searchValue,
      sortList1,
      sortList2,
      sortList3,
      sortList4,
      sortValue,
      setSortValue,
      toggleBodyStyle,
      goToProgram,
      learningTime,
      clearFilters,
      handleDebounceSearch,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}

.programsSort {
  padding: 20px;
  margin: auto;

  &__itemsBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    &:not(:nth-child(0)) {
      margin-top: 8px;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    background-color: $colorSelectGrey;
    flex-grow: 1;
    text-wrap: nowrap;
    border-radius: 10px;
    cursor: pointer
    font-size: 12px;

    &:hover {
      opacity: .6;
    }

    &_active {
      background-color: $colorPrimaryDpo;
      color: $colorFiller;
    }

    &_disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &__itemClear {
    background-color: $colorFiller;
    border: 1px solid $colorSelectGrey;
  }

  &__search {
    margin-top: 20px;
  }

  &__searchIcon {
    width: 24px;
    height: 24px;
  }

  @media (min-width:1400px) {
    max-width: 1400px;
  }

  +mediaTablet() {
    &__item {
      font-size: 14px;
    }
  }

  +mediaGiant() {
    &__item {
      font-size: 18px;
    }
  }
}

.programsList {
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 170px;
  display: grid;
  grid-template-columns: repeat(1, 80%);
  justify-content: center;
  gap: 40px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    justify-self: center;
  }

  &__imgBox {
    width: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
  }

  &__imgWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000000CC, #00000000);
    z-index: 2;
  }

  &__img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__tabsList {
    display: flex;
    gap: 5px;
  }

  &__listItem {
    padding: 10px;
    border: 1px solid #E24E25;
    border-radius: 30px;
    font-size: 12px;
  }
  &__imgContent {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    font-size: 12px;
  }

  &__nmo {
    padding: 10px;
    background-color: $colorFiller;
    border-radius: 30px;
    color: #E24E25;
  }

  &__title {
    flex-grow: 1;
  }

  &__btn {
    margin-top: 13px;
    width: 238px;
    text-align: center;
    padding: 14px 0;
  }

  +mediaPhone() {
    &__item {
      width: 420px;
    }

    &__imgBox {
      height: 272px;
    }
  }

  +mediaTablet() {
    &__imgContent, &__listItem {
      font-size: 16px;
    }
  }

  +mediaLarge() {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }

  +mediaGiant() {
    &__imgContent, &__listItem {
      font-size: 18px;
    }
  }
  @media (min-width:1400px) {
    max-width: 1400px;
    grid-template-columns: repeat(3, calc(33% - 20px));
  }
}
</style>
