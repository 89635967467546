export const menu = [
  {
    title: "Главная",
    link: "/",
  },
  {
    title: "О центре",
    link: "",
  },
  {
    title: "Новости",
    link: "",
  },
  {
    title: "Контакты",
    link: "",
  },
];
